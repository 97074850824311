import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Text } from "../../../components/atoms/Text/Text";
import { convertPeriodDate } from "../../../features/helpers/projectHelper";

type Props = {
  retailPrice: string;
  calcPrice: number;
  startDiscountPeriod?: string;
  endDiscountPeriod?: string;
  periodDiscountRate?: number;
  isWithinPeriod?: boolean;
};

export const ProductPrice = (props: Props) => {
  const {
    retailPrice,
    calcPrice,
    startDiscountPeriod,
    endDiscountPeriod,
    periodDiscountRate,
    isWithinPeriod = false,
  } = props;

  const basePrice = Number(retailPrice).toLocaleString();

  const useStyles = makeStyles({
    priceArea: {
      marginTop: 20,
      color: "rgb(74,74,74)",
    },
    discountContainer: {
      marginLeft: 20,
    },
    discountArea: {
      marginTop: 4,
      color: "rgb(74,74,74)",
      display: "flex",
      gap: 4,
    },
    tax: {
      fontSize: 12,
      textDecoration: "none",
    },
    shipPrice: {
      fontSize: 14,
      marginLeft: 20,
    },
    price: {
      marginLeft: 20,
      color: "rgb(80, 80, 80)",
    },
    discountedPrice: {
      textDecoration: "line-through",
      marginLeft: 4,
      color: "rgb(80, 80, 80)",
    },
    discountRate: {
      backgroundColor: "#ED1A3D",
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  // const calcPrice = (price: string, rate?: number) => {
  //   const castPrice = Number(price);
  //   if (!rate) return castPrice.toLocaleString();

  //   if (isWithinPeriod) {
  //     const discountAmount = Math.ceil(castPrice * (rate / 100));
  //     const discountedPrice = castPrice - discountAmount;
  //     return discountedPrice.toLocaleString();
  //   }
  //   return castPrice.toLocaleString();
  // };

  return (
    <div className={classes.priceArea}>
      {isWithinPeriod && (
        <div className={classes.discountContainer}>
          <Text
            isDark
            text={`${convertPeriodDate(
              startDiscountPeriod ?? "",
              endDiscountPeriod ?? ""
            )}`}
          />
          <div className={classes.discountArea}>
            <div className={classes.discountRate}>
              <Text color="white" text={`${periodDiscountRate}% OFF`} />
            </div>
            <Typography variant="h6" className={classes.discountedPrice}>
              {`¥${basePrice} `}
            </Typography>
          </div>
        </div>
      )}
      <Typography variant="h5" className={classes.price}>
        {`¥${calcPrice?.toLocaleString()} `}
        <span className={classes.tax}>(税込)</span>
      </Typography>
      <Typography className={classes.shipPrice}>
        送料 0円(無料キャンペーン中)
      </Typography>
      {/* <Typography className={classes.shipPrice}>送料 600円</Typography> */}
    </div>
  );
};
