import { makeStyles, Typography } from "@material-ui/core";
import { ReservePriceDetail } from "./ReservePriceDetail";

type Props = {
  paymentAmount: number;
  discountedPrice: number;
  discountRate: number;
  isNotExist?: boolean;
  quantity: number;
};

export const ReservePriceTotal = (props: Props) => {
  const { paymentAmount, discountedPrice, discountRate, isNotExist, quantity } =
    props;
  const useStyles = makeStyles({
    totalPrice: {
      width: 320,
      margin: "20px auto 0",
      borderBottom: "1px solid gray",
    },
    total: {
      display: "flex",
      width: 320,
      margin: "10px auto",
    },
    left: {
      flex: 1,
    },
    right: {
      flex: 1,
    },
    totalText: {
      fontSize: 14,
    },
    priceArea: {
      padding: "0 20px",
    },
  });
  const classes = useStyles();
  console.log(111, paymentAmount);
  const totalAmount = paymentAmount * quantity;

  const accountingPriceCalculation = Math.floor(totalAmount) + 0; // キャンペーン中
  const accountingPrice = accountingPriceCalculation.toLocaleString();
  return (
    <div className={classes.priceArea}>
      <div className={classes.totalPrice}>
        <ReservePriceDetail
          label="商品合計（税込）"
          price={`¥ ${Math.floor(totalAmount).toLocaleString()}`}
        />
        <ReservePriceDetail label="送料" price="¥ 0" />
        {/* <ReservePriceDetail label="送料" price="¥600" /> キャンペーン中 */}
        {isNotExist === false && (
          <>
            <ReservePriceDetail
              label="割引額"
              price={`¥ -${discountedPrice.toLocaleString()}`}
            />
            <ReservePriceDetail label="割引率" price={`${discountRate}%`} />
          </>
        )}
      </div>
      <div className={classes.total}>
        <div className={classes.right}>
          <Typography className={classes.totalText}>合計（税込）</Typography>
        </div>
        <div className={classes.left}>
          <Typography align="right" className={classes.totalText}>
            {`¥${accountingPrice}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
